@use '../_module.scss';

.skill-row {
    display: flex;
    justify-content: center;
}

// need to refactor the styling on the about section so I can fix the p tags here. 

.skills-text {
    text-align: center;

}

@media screen and (max-width: 720px) {

    .skill-row-wrapper {
        display: flex;
        justify-content: center;
    }

    .skill-row {

        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        
    }
    
    .skills-text {

        text-align: center;
    
    }
}