@use '../module.scss';

.skills-container {

    height: 334px;
    width: 283px;
    margin: 10px;
    text-align: center;

}

.skills-img {

    width: 200px;
    height: 200px;
    color: white
    
}

.skills-text {

    @include module.text-style;
    
}

@media screen and (max-width: 780px) {

    .skills-container {

        height: 111px;
        width: 94px;
        margin: 20px;
        text-align: center;
        
    }
    
    .skills-img {
    
        width: 94px;
        height: 94px;
        color: white
        
    }
    
    .skills-text {
    
        @include module.text-style;
        
    }

}
