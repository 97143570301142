$primary: #C9AD4C;
$dark-primary: #04131E;
$primary-text: white;
$secondary: #3B350F;

@mixin base-text {
    color: $primary;
}

@mixin text-style {
    color: $primary-text;
    font-family: 'Gelasio', serif;
}

@mixin lrg-button {
    @include base-text;
    cursor: pointer;
    text-decoration: none;
    font-size: 36px;
    border: 2px solid $primary;
    border-radius: 10px;
    width: 166px;
    height: 54px;
    position: relative;  
    background-color: transparent;
}

@mixin lrg-button-before {
    content: "" ;
        // pointer-events: none;
        // position: absolute;
        // background: $primary;
        // top: 70%;
        // left: 0 ;
        // width: 100%;
        // height: 100%;
        // transform: perspective(1em) rotateX(40deg) scale(1, 0.35) ;
        // filter: blur(2em);
        // opacity: 0.7;
}

@mixin lrg-button-after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em $primary;
    opacity: 0; 
    background: #ffcd17;
    z-index: -1;
    transition: opacity 100ms linear;
    border-radius: 8px;
}


@mixin glow-effect($selector) {
    
    #{$selector}:hover/* ,
    #{$selector}:focus  */{

        color:hsl(323 21% 16%);
        text-shadow: none;
    }

    #{$selector}:hover::before/* ,
    #{$selector}:focus::before */{
        pointer-events: none;
        position: absolute;
        background: $primary;
        top: 70%;
        left: 0 ;
        width: 100%;
        height: 100%;
        transform: perspective(1em) rotateX(40deg) scale(1, 0.35) ;
        filter: blur(2em);
        opacity: 0.7;
    }

    #{$selector}:hover::after/* ,
    #{$selector}:focus::after */{
        opacity: 1;
    }

}

@mixin small-button {
    cursor: pointer;
    width: 100px;
    border: 1px solid $primary;
    color: $primary;
    margin: 10px;
    position: relative;  
}

@mixin small-button-before {
    content: "" ;
}

@mixin small-button-after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em $primary;
    opacity: 0; 
    background: #ffcd17;
    z-index: -1;
    transition: opacity 100ms linear;
    border-radius: 0px;
}

@mixin glow-effect-class($selector) {
    
    .#{$selector}:hover/* ,
    .#{$selector}:focus */ {

        color:hsl(323 21% 16%);
        text-shadow: none;
    }

    .#{$selector}:hover::before/* ,
    .#{$selector}:focus::before */{
        pointer-events: none;
        position: absolute;
        background: $primary;
        top: 70%;
        left: 0 ;
        width: 100%;
        height: 100%;
        transform: perspective(1em) rotateX(40deg) scale(1, 0.35) ;
        filter: blur(2em);
        opacity: 0.7;
    }

    .#{$selector}:hover::after/* ,
    .#{$selector}:focus::after */{
        opacity: 1;
    }

}

@mixin lrg-button-mobile {
    @include base-text;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px;
    border: 2px solid $primary;
    border-radius: 10px;
    width:  100px;
    height: px;
    position: relative;  
    background-color: transparent;
}

@mixin lrg-button-before-mobile {
    content: "" ;
}

@mixin lrg-button-after-mobile {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em $primary;
    opacity: 0; 
    background: #ffcd17;
    z-index: -1;
    transition: opacity 100ms linear;
    border-radius: 8px;
}


@mixin glow-effect-mobile($selector) {
    
    #{$selector}:hover,
    #{$selector}:focus {

        color:hsl(323 21% 16%);
        text-shadow: none;
    }

    #{$selector}:hover::before,
    #{$selector}:focus::before{
        pointer-events: none;
        position: absolute;
        background: $primary;
        top: 70%;
        left: 0 ;
        width: 100%;
        height: 100%;
        transform: perspective(1em) rotateX(40deg) scale(.5, 0.35) ;
        filter: blur(2em);
        opacity: 0.5;
    }

    #{$selector}:hover::after,
    #{$selector}:focus::after{
        opacity: 1;
    }

}
