@use '_module.scss';

:root {
  width: 100%;
}

body {
  width: 100%;
  background-color: module.$dark-primary;
}

.App {
  width: 100%;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



@media screen and (max-width: 600px) {

  :root {
      width: 100%;
  }

}
